import prot_mheu from "../images/iris-warehouse/prot_mheu.jpg"
import prot_tan from "../images/iris-warehouse/prot_tan.jpg"
import prot_spu from "../images/iris-warehouse/prot_spu.jpg"
import prot_peu from "../images/iris-warehouse/prot_peu.jpg"
import prot_dap from "../images/iris-warehouse/prot_dap.jpg"
import prot_was from "../images/iris-warehouse/prot_was.jpg"
import prot_inma from "../images/iris-warehouse/prot_inma.jpg"

const options = [
  {
    key: "opt-boef",
    head: "Boost Efficiency",
  },
  {
    key: "opt-rtno",
    head: "Real-time Notification",
  },
  {
    key: "opt-load",
    head: "Live Object and Action Detection",
  },
]

const enhancements = [
  {
    key: "enc_rnmi",
    title: "Report Near Misses",
    text: `
        Identify potential workplace hazards and avoid accidents.
    `,
  },
  {
    key: "enc_asut",
    title: "Asset Utilization",
    text: `
        Boost operational proficiency by monitoring machines, vehicles, boxes, docks, personnel, etc.
    `,
  },
  {
    key: "enc_efsa",
    title: "Encroachment/Forklift Safety",
    text: `
        Separate pathways for pedestrians and forklifts are defined & monitored by IRIS to avoid accidents. It raises an alarm if it detects individuals or objects placed in these encroachment free zones.
    `,
  },
  {
    key: "enc_fare",
    title: "Face Recognition",
    text: `
        Mark contactless attendance of employees, identify any intruders and detect the number of people in a given area with their information.
    `,
  },
  {
    key: "enc_fith",
    title: "Fire and Thermal",
    text: `
        Identification of potential causes of fire. Early detection and speedy extinguishment is the primary concern.
    `,
  },
  {
    key: "enc_voca",
    title: "Volume Calculation",
    text: `
        IRIS can count stacked containers as well as boxes on a conveyor belt and recognize their labels, if needed.
    `,
  },
]

const protections = [
  {
    key: "prot_mheu",
    title: "MHE Utilization",
    detText: `
        Warehouses handle thousands of inventory units which are brought in and shipped out on a daily basis. Typically, these handling operations are carried out by manually operated material handling equipment (MHE) such as forklifts, lift-trucks, tow-tuggers, tow-carts, cranes, etc.
        
        As time is an expensive operating cost for warehouses, MHE shouldn’t stay idle or travel around the warehouse to execute false pick and drop operations. IRIS identifies the type of vehicles in the warehouse and generates analytics on their turn-around-time, heat maps, distance travelled, etc.; providing a clearer picture of MHE Utilization, curbing dispatch delays. It calculates the number of trips a vehicle has made, target trips, average waiting time, unloading/loading time for various vehicles, average manhours, and average CBM. It also enhances forklift productivity by calculating the pallet movement and monitoring the loading and unloading time. It generates the heat map of the MHE movement, HOPT movement and Utilization.
    `,
    ptTitle: "Thus, IRIS can compute:",
    points: [
      "Vehicle Type",
      "Vehicle Trip Utilization",
      "Average Loaded/unloaded time",
      "Pallets Moved",
      "Heat map of MHE movement",
      "HOPT movement and Utilization",
      "Distance Travelled",
    ],
    swipe: true,
    video: null,
    poster: prot_mheu,
  },
  {
    key: "prot_tan",
    title: "Truck Analytics",
    detText: `
        Warehouses are abuzz with many fleets of trucks entering their premises daily. However, many times, between their entry and exit, trucks could have idle time as their movement into and within the premises was not synchronized with loading and dispatch schedules. Such bottlenecks could result in bay underutilization, overutilization of docks, and dispatch inefficiency.

        IRIS combats this lack of visibility of the fleet movement by monitoring the number of trucks going in and out of the premises, monitoring the loading, unloading, and idle time. IRIS raises an alert if the vehicle is placed at a bay with its door open without loading or unloading for more than 30 minutes.

        It also evaluates space utilization within the truck by comparing its storage capacity vs the volume of each box being loaded in it. This reduces the number of round trips from the warehouse and ensures an efficient supply chain. Identifying such potential bottlenecks will lower the truck TAT, and also lead to better dock utilization, space utilization and enhance the overall dispatch process.
    `,
    ptTitle: "IRIS generates insights on:",
    points: [
      "ANPR of a vehicle parked at the dock",
      "Truck type",
      "Number of trucks",
      "Loading/unloading time",
      "Idle time",
      "Space utilization inside a truck",
    ],
    swipe: false,
    video: null,
    poster: prot_tan,
  },
  {
    key: "prot_spu",
    title: "Space Utilization",
    detText: `
        Space is an expensive but essential investment for warehouses. Yet, it is one of the least prioritized areas when it comes to process optimization. This can be due to a lack of data-driven insights that can unlock the latent warehouse capacity. IRIS can calculate the proper utilization of space by monitoring bay area, docks usage, and placement of boxes, which can help curb costs and improve warehouse processes significantly.

        By generating insights on dock availability, number of docks, types of vehicle, loading/unloading time, average wait time, number of bay areas for box placement, the efficiency of piling boxes, outbound box orders, and stacking of inbound boxes, IRIS can ensure better space utilization.

        IRIS can monitor if the boxes are placed close to the loading dock and also record the vehicle number plates. It also evaluates space utilization within a vehicle which reduces the number of round trips from the warehouse resulting in lower operational and storage costs.
    `,
    ptTitle: "Thus, IRIS can monitor:",
    points: [
      "Dock utilization",
      "Number of docks",
      "Bay area utilization",
      "Wait time at dock",
      "Utilization by activity type such as loading/unloading/idle",
      "Vehicle space utilization",
    ],
    swipe: true,
    video: null,
    poster: prot_spu,
  },
  {
    key: "prot_peu",
    title: "Personnel Utilization",
    detText: `
        Each warehouse has its own processes and systems in place, yet the journey before the goods are dispatched to the customers differ from company to company. A lot of it is primarily manual and often, there is a lack of insight on the potential productivity of personnel involved. Aspects such as optimal headcount, time taken for various activities, etc. could lead to better resource allocation and in turn, improve process efficiency.

        IRIS can help monitors the number of workers per inbound/outbound job by observing intra-warehouse movements, time taken to load dispatch order, average time for palletization, inspection, repalletization, HOPT – pickup/drop, Forklift-Pickup/drop, and idle time. In each shift, it generates unbiased insights on staff efficiency.

        Another important aspect is staff health. Manual work such as lifting or moving heavy objects, repetitive or sustained activities can lead to MSDs. IRIS can identify and warn if the workers are not following the right work posture.
    `,
    ptTitle: "Thus, IRIS can generate insights on:",
    points: [
      "Time Taken To Load Dispatch Order",
      "Average Time For Palletization",
      "Repalletization",
      "Inspection",
      "Forklift, Or Other Vehicles Pickup/ Put-away",
      "Identify correct posture while lifting heavy goods",
    ],
    swipe: false,
    video: null,
    poster: prot_peu,
  },
  {
    key: "prot_dap",
    title: "Damage and Pilferage",
    detText: `
        As warehouses deal with thousands of packages, these packages are vulnerable to damage and pilferage during the transfer process. IRIS, our computer vision platform, can ensure quality assurance of goods in the premises by monitoring real-time anomalies as damaged boxes, unauthorized entry, and send alerts for suspicious activities or tampering.

        IRIS ensures to capture details and incidents which may damage the contents of the packages. For instance, boxes falling off from pallet or during unloading when the vehicle door opens, or workers standing/sitting/kicking/throwing boxes, or boxes that are already pressed and/or wet and need to be identified.

        IRIS raises real-time alarms in case of tampering such as opening sealed boxes, taking out piece from the box, shipment detection other than dock area, or someone carrying a box in hand without placing it on the pallet, keeping box aside (not inside the vehicle) after scanning while loading a vehicle, carrying boxes outside the hub, etc.

        Further, most warehouses have limits on the height of stacking the goods. IRIS can ensure that they are stacked at the maximum defined height or less. Likewise, putting heavy liquid drum on containers is prohibited and IRIS can monitor it for compliance.

        Other issues such as broken pallets being used for operations or oil spilt on the operational floor area could lead to dangerous accidents and need to be monitored.
    `,
    ptTitle: "IRIS ensures:",
    points: [
      "Quality assurance of goods",
      "Real-time monitoring for damaged boxes",
      "Real-time alerts for unauthorized entry, tampering or theft",
    ],
    swipe: true,
    video: null,
    poster: prot_dap,
  },
  {
    key: "prot_was",
    title: "Warehouse Security",
    detText: `
        Although manual security is a fundamentally a trust-driven system with its own advantages, it is fraught with risks. Therefore, a smart technological intervention could provide robust support to its human counterparts as well as monitor their activity too.

        For instance, IRIS can raise alarms if someone moves into the restricted area, or the entry/exit is not from a designated area. Timely intervention can prevent serious incidents.

        Likewise, IRIS is trained to capture timestamp when the security checks the vehicle at the main gate, and raise an alert if the vehicle is not checked. It also raises alerts if the loading vehicle departed without a seal or a vehicle arrives without the seal.

        It can monitor intrusion by detecting someone climbing the outer wall or main entrance, human movement in the vehicle tail area, unauthorised dock access, human or vehicle movement (open/close) during silent hours, cage movement in an unauthorised area, dock door movement during silent hours, etc.
    `,
    ptTitle: "IRIS monitors:",
    points: ["Intrusion", "Vehicle stamping", "Movement during silent hours"],
    swipe: false,
    video: null,
    poster: prot_was,
  },
  {
    key: "prot_inma",
    title: "Identifies Near-Misses and Accidents",
    detText: `
        A "near miss" is a spontaneous event that had the potential to result in injury, illness, or damage but did not do so. Statistics show that for every 600 near misses, there are 30 incidents that result in damage to property, 10 serious injuries and 1 fatality.

        Improper lifting, walking under an overhead load, not using Personal Protective Equipment, poorly maintained equipment, oil or grease on floors, things laid in walkways, trash and boxes left in pathways, can all lead to serious accidents. IRIS monitors the premises and scouts for possible threats. It identifies near-misses and reduces accidents by real-time intervention with alarms or alerts.
        
        For example, IRIS creates virtual boundaries to separate the path for vehicles and pedestrians and raises alerts if the pathway is not followed. It monitors trucks, forklifts, pallet movement, loading and unloading of goods, and monitors any potential danger of falling goods. It also monitors premises for any dangerous gap between vehicles and prevents potential accidents.

        IRIS spots a spark and identifies a fire in under 10 seconds and raises alarms. It also identifies if employees are violating any PPE compliance inside the facility.
    `,
    ptTitle: "IRIS ensures safety by:",
    points: [
      "Ensuring pathway compliance",
      "Monitoring premises for potential hazards",
      "Identifying fire",
    ],
    swipe: true,
    video: null,
    poster: prot_inma,
  },
]

export const getWarehouseOptions = () => options
export const getEnhancements = () => enhancements
export const getProtections = () => protections
