import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IRIWHImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      enc_rnmi: file(relativePath: { eq: "iris-warehouse/enc_rnmi.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enc_asut: file(relativePath: { eq: "iris-warehouse/enc_asut.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enc_efsa: file(relativePath: { eq: "iris-warehouse/enc_efsa.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enc_fare: file(relativePath: { eq: "iris-warehouse/enc_fare.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enc_fith: file(relativePath: { eq: "iris-warehouse/enc_fith.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enc_voca: file(relativePath: { eq: "iris-warehouse/enc_voca.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default IRIWHImg
