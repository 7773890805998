import React, { Component } from "react"
import "../scss/iriswarehouse.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { navbarUpdate, setDefaultHeaderTheme } from "../utils/common-util"
import {
  getWarehouseOptions,
  getEnhancements,
  getProtections,
} from "../data/iriswarehouse"
import IRISWHImg from "../components/iris-wh-img"

// var scroll = 0;

const videoWHRatio = 1.777

class IRISWarehousePage extends Component {
  constructor(props) {
    super(props)
    this.enhancementVideos = {}
    this.state = {
      currentScroll: 0,
    }

    // this.onPageScroll = this.onPageScroll.bind(this);
    // this.scrollToSection = this.scrollToSection.bind(this);
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)
    navbarUpdate("nav-products", "nav-iris")
    this.setState({
      currentScroll: window.scrollY,
      isMobile: window.innerWidth < 769,
    })
    for (const key in this.enhancementVideos) {
      if (
        this.enhancementVideos.hasOwnProperty(key) &&
        this.enhancementVideos[key]
      ) {
        const $wrap = this.enhancementVideos[key]
        let height = $wrap.clientWidth / videoWHRatio
        $wrap.firstElementChild.style.height = height - 1 + "px"
      }
    }
    // window.addEventListener("scroll", this.onPageScroll, false);
  }

  // onPageScroll = (e) => {

  // }

  // scrollToSection(key, e) {
  // let $html = document.getElementsByTagName('HTML')[0];
  // if(!$html.classList.contains('scroll-smooth')) {
  //     $html.classList.add('scroll-smooth');
  // }
  // document.getElementById(key).scrollIntoView({block: "center"});
  // setTimeout(() => {
  //     $html.classList.remove('scroll-smooth');
  // }, 300);
  // }

  // componentWillUnmount() {
  //     window.removeEventListener("scroll", this.onPageScroll, false);
  // }

  getProtDetailsRow = prot => {
    return (
      <div
        id={prot.key + "-row"}
        key={prot.key}
        className={"iwpdc-row" + (prot.swipe ? " iwpdc-swipe" : "")}
      >
        <div className="iwpdc-title">{prot.title}</div>
        <div className="iwpdc-desc">{prot.detText}</div>
        <div className="iwpdc-3sec-cont">
          <div className="iwpdc-points">
            <div className="iwpdc-points-head">How does IRIS help?</div>
            <ul>
              {prot.points.map((pt, i) => {
                return (
                  <li key={prot.key + "-point-" + i} className="iwpdc-point">
                    {pt}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="iwpdc-gap"></div>
          <div
            className="iwpdc-video"
            ref={r =>
              (this.enhancementVideos[prot.key] = prot.video ? r : null)
            }
          >
            <video
              loop
              preload="auto"
              autoPlay
              muted
              crossOrigin="anonymous"
              poster={prot.poster}
            >
              {prot.video ? <source src={prot.video} type="video/mp4" /> : null}
              Your browser does not support the video tag...
            </video>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const options = getWarehouseOptions()
    const enhancements = getEnhancements()
    const protections = getProtections()

    return (
      <Layout>
        <SEO title="IRIS for Warehouse" />
        <div className="iriswh-top-content">
          <div className="top-heading lr-padding">IRIS for Warehouse</div>
          <div className="top-desc lr-padding">
            You can now experience complete ongoing visibility into your
            warehouse with IRIS, our AI-powered computer vision platform, to
            achieve high efficiency and zero accidents in warehouses. Using
            existing CCTVs, it generates analytics on productivity, utilization,
            and optimization while keeping an eye out for damage and pilferage.
            It raises alarms on detecting non-compliance in the form of
            real-time notifications that can be configured to be sent as
            Whatsapp, SMS, buzzers or PA system announcements. A dashboard
            provides actionable insights and analytics that can be accessed via
            the app or web.
          </div>
          <div className="wh-opt-wrap">
            <div className="wh-options lr-padding">
              {options.map(opt => (
                <div key={opt.key} className="wh-option">
                  <span className={"wh-opt-icn " + opt.key}></span>
                  <div className="opt-head">{opt.head}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="iris-enhancements-content iww-container">
          <div className="enhan-heading">
            How IRIS Enhances Warehouse Operations
          </div>
          <div className="enhancements">
            {enhancements.map(prot => {
              return (
                <div key={prot.key} className="enhancement">
                  <IRISWHImg name={prot.key}></IRISWHImg>
                  <div className="enhan-title">{prot.title}</div>
                  <div className="enhan-desc">{prot.text}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="iris-wh-prot-details-content iww-container">
          {protections.map(this.getProtDetailsRow)}
        </div>
      </Layout>
    )
  }
}

export default IRISWarehousePage
